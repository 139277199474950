class Slider {
	constructor() { 
		this.bullets = ""
		this.slides = ""
		this.sliderContainer = ""
		this.nextButton = document.querySelector(".next__button")
		this.currentIndex = 0
	}
	
	init() {
		this.bullets = document.querySelectorAll(".bullet")
		this.nextButton = document.querySelector(".next__button")
		this.prevButton = document.querySelector(".prev__button")
		this.slides = document.querySelectorAll(".single__slide")
		this.sliderContainer = document.querySelector(".slider__wrapper")
		this.nextButton.addEventListener("click", () => this.nextSlide())
		this.prevButton.addEventListener("click", () => this.prevSlide())
		this.bullets.forEach((bullet, index) => {
			bullet.addEventListener("click", () => {
				this.currentIndex = index
				this.showSlide()
			})
		})
	}

	showSlide() { 
		this.sliderContainer.style.left = this.currentIndex * -100 + `%`
		this.bullets.forEach(bullet => {
			bullet.classList.remove("active")
		})
		this.bullets[this.currentIndex].classList.add("active")
	}

	nextSlide() { 
		this.currentIndex++
		if (this.currentIndex > this.slides.length - 1) {
			this.currentIndex = 0
		}
		this.showSlide()
	}

	prevSlide() {
		this.currentIndex--
		if (this.currentIndex < 0) {
			this.currentIndex = this.slides.length - 1
		}
		this.showSlide()
	}
}

const CustomSlider = new Slider()

window.addEventListener("DOMContentLoaded", () => {
	CustomSlider.init()
})