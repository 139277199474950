class Modal {
	openModal() {
		// get template and clone content
		const modalTemplate = document.querySelector('#modal');

		const modal = modalTemplate.content.cloneNode(true);
		document.body.appendChild(modal);
	}
	closeModal() {
		const modal = document.querySelector('.modal');
		modal.remove();
	}
}

const modal = new Modal()