class TodoIntersectionObserver{
	constructor() {

		this.options = {
			root: null,
			threshold: 0.5,			
		}

		this.observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => { 
				if(entry.isIntersecting){
					entry.target.classList.add('is-visible');
				} else {
					// entry.target.classList.remove('is-visible');
				}
			})
		 }, this.options);
	}

	observe(element) {
		this.observer.observe(element);
	}
}





const todoIntersectionObserver = new TodoIntersectionObserver();

window.addEventListener('DOMContentLoaded', (e) => { 
	const elements = document.querySelectorAll('[data-animate-in]') || [];
	elements.forEach(element => {
		todoIntersectionObserver.observe(element);
	});
})